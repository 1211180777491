import React from 'react';
import Group from "../assets/Group.svg"
const Pricing = () => {
  return (
    <section className='md:pt-10 px-4 md:px-20 pb-[70px]' id='price'>
      <div className="container">
                <div className="wow fadeInUp mx-auto mb-9  text-center lg:mb-[50px]">
                    <h2 className="mb-4 text-2xl text-white dark:text-white sm:text-4xl md:text-[36px] md:leading-tight">
                        Ginux Pricing List
                    </h2>
                </div>
            </div>
     <div className="p-5 sm:p-6 md:p-8 md:grid-cols-2 grid items-center lg:grid-cols-4 gap-6 rounded-lg">
        <div className=' rounded-lg bg-purple-700 p-4  pt-[30px] text-white w-full sm:w- text-3xl  py-4 rounded-md row-span-2'>
           <h1 className='text-gray-300  font-avenir text-2xl font-extrabold mb-2 '>Pay-Per-Use</h1>
           <p className='text-gray-400 font-inter text-base font-medium leading-[150.673%]'>Fund your wallet and Enjoy the flexibility of GinuxAI's <span className='text-yellow-500'>pay-per-use</span> model.</p>
           <img src={Group} alt=''/>
        </div>

        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[30px] gap-[20px] p-8  '>
           <h2 className="text-white font-avenir text-xl  leading-[119.1%] mb-2 ">Chapter Layout</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Auto generate chapter<br/>breakdown</p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%] '>₦30</span>per access</h1>
        </div>
        
        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[20px]  p-8  '>
        <h2 className="text-white font-avenir text-xl leading-[119.1%] mb-3">Access Resources</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Get access to thousands of<br/> project materials</p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%]'>₦50</span>per access</h1>        
        </div>


        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[20px] p-8  '>
        <h2 className="text-white text-xl leading-[119.1%] mb-2">Generate Project topic</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Generate endless project<br/> topics </p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%]'>₦30</span>per access</h1>
        </div>
        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[20px] p-8  '>
        <h2 className="text-white font-avenir text-xl leading-[119.1%] mb-3">Text Extractor</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Extract text effortlessly from <br/> image</p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%]'>₦50</span>per access</h1>
        
        </div>
        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[20px] p-8  '>
        <h2 className="text-white font-avenir text-xl  leading-[119.1%] mb-3">Chat with PDF</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Interact with your pdf <br/> documents </p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%]'>₦50</span>per access</h1>
        </div>
        <div className='bg-gray-800 w-full sm:w- text-white text-3xl py-4 rounded-md pt-[30px] pb-[20px] p-8  '>
        <h2 className="text-white font-avenir text-xl  leading-[119.1%] mb-3">Consultation</h2>
           <p className='text-gray-400 font-inter text-sm  leading-[150.673%] mb-3'>Get expert advice from <br/> scholars</p>
            <h1 className='text-xl pricing-border'><span className='text-yellow-500 text-center font-inter text-xl pl-1 pr-2 font-medium leading-[150.673%]'>₦5,000</span>per access</h1>
        </div>

     </div>
    </section>
  );
};

export default Pricing;
