import React from 'react';
import docu from "../assets/Frame34.png";
export default function Document(){
    return(
        <div className="flex flex-col md:flex-row justify-center items-center pb-[75px] pt-[95px] gap-14 pt-10 md:pt-20 px-4 md:px-0">
        <div>
            <h2 className="text-2xl font-bold mb-10">Interact with PDF Documents</h2>
            <p className="text-gray-400 mb-10"> GinuxAI's Text Extractor helps to quickly and accurately scan, summarize,<br/> and highlight key information from books, articles,<br/> and other sources.<br/> This tool ensures you spend less time sifting through content and more time<br/> focusing on your research and writing...</p>
            <button onClick={()=>window.location.assign("https://app.ginuxai.com/auth/signup")} className="rounded-md border border-solid border-gray-600 bg-gradient-to-r from-blue-500 via-purple-500 to-purple-800 px-7 py-2 hover:bg-purple-700 hover:border-purple-900 hover:text-white">
                Get Started Now
            </button>
        </div>

        <div className="bg-purple-700 rounded-lg p-4 w-full md:w-[550px] h-[400px] md:p-9 flex items-center justify-center">
            <img
                src={docu}
                alt="Description of the pic"
                className="  w-full h-auto max-w-full object-cover rounded-lg"
            />
        </div>
    </div>
    )
}