import React from "react";

export default function Advert() {
  return (
    <section className="pt-10 px-9 lg:px-0 md:pt-20 lg:pt-32 pb-10 md:pb-20 lg:pb-32">
      <div className="flex flex-col md:flex-row w-full max-w-screen-xl mx-auto px-6 md:px-8 lg:px-16  h-[400px] md:h-[160px] justify-center items-center rounded-md" style={{ background: 'var(--gradient, linear-gradient(90deg, #2B59FF 0%, #BB2BFF 100%))' }}>
        <div className="text-center md:text-left flex flex-col md:flex-row gap-4 md:gap-8">
          <h1 className="text-xl md:text-3xl lg:text-3xl font-bold leading-tight">
            It will help you improve your writing <br /> & bring ideas more clearly.
          </h1>
        </div>
        <button onClick={()=>window.location.assign("https://app.ginuxai.com/auth/signup")} className=" ml-7 mt-4 md:mt-0 rounded-md border border-solid border-gray-600 bg-white text-black px-6 py-3 hover:bg-purple-700 hover:border-purple-900 hover:text-white focus:outline-none focus:ring focus:border-purple-900">
          Get Started Now
        </button>
      </div>
    </section>
  );
}
