import React from 'react';
import './App.css';
import Header from "./Components/Header";
import Home from "./Components/Home";
import About from './Components/About';
import User from "./Components/User";
import Document  from './Components/Document';
import Research from './Components/Research';
import Project from "./Components/Project";
import Help from './Components/Help';
import Customer from './Components/Customer';
import Advert from './Components/Advert';
import Frontend from "./Components/Frontend";
import Pricing from './Components/Pricing';
function App() {
  return (
    <div className="App">
        <Header/>
        <Home/>
        <About/>
        <User/>
        <Document/>
        <Research/>
        <Project/>
        {/* <Help/> */}
         <Pricing/>
        <Customer/>
        <Advert/>
        <Frontend/>
    </div>
  );
}

export default App;
