import React from "react";
import Search from "../assets/interface.png";

export default function Research() {
    return (
        <>
        
        <div className="flex flex-col md:flex-row justify-center items-center pb-[75px] pt-[75px] gap-14 pt-10 md:pt-20 px-4 md:px-0">
            <div className="bg-purple-700 rounded-lg p-4 w-full md:w-[550px] h-[400px] md:p-9 flex items-center justify-center">
                <img
                    src={Search}
                    alt="Description of the pic"
                    className="  w-full h-auto max-w-[412px] object-cover rounded-lg"
                />
            </div>
            <div>
                <h2 className="text-2xl font-bold mb-10">Research Center</h2>
                <p className="text-gray-400 mb-10"> Find a wealth of resources to aid you in your academic pursuits. <br/>Our vast database contains scholarly articles, <br/>research papers, and a multitude of sources across various subjects.<br/> GinuxAI helps you discover and access relevant materials to support your<br/> projects and expand your knowledge...</p>
                <button onClick={()=>window.location.assign("https://app.ginuxai.com/auth/signup")} className="rounded-md border border-solid border-gray-600 bg-gradient-to-r from-blue-500 via-purple-500 to-purple-800 px-7 py-2 hover:bg-purple-700 hover:border-purple-900 hover:text-white">
                    Get Started Now
                </button>
            </div>
        </div>
        </>
        
    );
}
