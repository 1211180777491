import React from "react";
import Elip from "../assets/Ellipse 5 (1).svg";
import Elipp from "../assets/Ellipse 5 (2).svg";
import Elipi from "../assets/Ellipse 5 (3).svg";
import Elipw from "../assets/Ellipse 5 (4).svg"
import Elipt from "../assets/Ellipse 5 (5).svg";
import Elipe from "../assets/Ellipse 5.svg";
export default function Customer() {
    return (
        <section className="py-10 mb-10 px-10 lg:px-0 bg-slate-800" id="Customer">
            <div className="">
                <div className="wow fadeInUp mx-auto mb-9  text-center lg:mb-[50px]">
                    <h2 className="mb-4 text-2xl text-center text-white dark:text-white sm:text-4xl md:text-[36px] md:leading-tight">
                       What our customers say
                    </h2>
                    <p className="text-gray-400">Don’t take our word for it. See what others are saying</p>
                </div>
            </div>

            <section className="py-10 flex mb-10">
                <div className="mx-auto max-w-7xl sm:px-8 md:px-12">
                    <div className=" grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {/* Grid Items */}
                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src="/jay.png" alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2">Oyeyemi Joy</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify"> As a researcher, Ginuxai is a must-have. Its vast database of research materials is a goldmine. The text extraction from images is precise, and the tool's guidance in project development is invaluable. Ginuxai simplifies complex tasks!</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>

                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src="/waliyat.png" alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2 text-sm">Morenikeji Waliyat</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify">Ginuxai is my go-to tool for quick access to relevant materials. It's user-friendly and helps in generating project topics effortlessly. Its chapter layout feature streamlined my reports. I've become more efficient at work thanks to Ginuxai!</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>

                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src="/charles.png" alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2">Adedayo Charles</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify">Using Ginuxai for my dissertation was a smart choice. Extracting text from images was a breeze, and the consultation feature guided me through the entire process. Ginuxai is an essential tool for any serious researcher.</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>

                        
                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src="/tonney.png" alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2">Tooney Francis</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify">Using Ginuxai has transformed my studying experience. I was able to generate relevant materials for my project topic. The project consultation guided me through complex assignments, making my work stand out. Ginuxai truly empowers students to excel in their academic endeavors!</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>

                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src="/tt.png" alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2">Oni Temitope</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify">Ginuxai's project topic generation was a lifesaver! It offered diverse, thought-provoking ideas that sparked new directions for my research. The chapter layout tool provided a structured framework, simplifying the organization of complex research projects.</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>
                        <div className="bg-black text-white flex flex-col items-start gap-4 p-4 w-full sm:w-[280px]">
                            <div className="flex justify-between items-center gap- pb-2">
                                <div className="flex items-center">
                                <img src={Elipe} alt="" className="w-10 h-10 rounded-full" />
                                <h3 className="pl-2">Annette Black</h3>

                                </div>
                               
                                <svg className="  pl-10 pt-[9px]" xmlns="http://www.w3.org/2000/svg" width="74" height="35" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 8.00175V8L21.875 5.97672C22.1807 5.64695 21.9058 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2564 4 16 4C13.7909 4 12 5.79086 12 8C12 8.52744 12.1021 9.03104 12.2876 9.49212C12.8942 11 11.5566 13.5 8.99979 16C7.86451 17.11 4.94613 17.4114 2.55791 17.1927C2.09318 17.1502 1.80143 17.6796 2.16081 17.9773C4.16334 19.6361 6.94072 20 9.49987 20C15.9992 20 20.499 14.501 20 8.00175Z" fill="#1C93D7" />
                                    <path d="M8.99932 16.0001C4.44839 14.6348 2.38277 9.54153 3.55651 5.62152C3.68428 5.19479 4.25972 5.21779 4.46011 5.61562C6.20805 9.08563 9.76874 9.50006 12.5 9.50006C18.5 9.50006 17 18.4003 8.99932 16.0001Z" fill="#1C93D7" />
                                </svg>
                            </div>
                            <p className="text-justify">The project topic generation feature provided fresh insights, while the chapter layout tool made organizing reports effortless. Ginuxai adds efficiency to research workflows!</p>
                            <span className="text-blue-700">#ginuxai</span>
                        </div>

                       
                        {/* End of Grid Items */}
                    </div>
                </div>
            </section>
        </section>
    )
}
