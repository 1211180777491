import React, { useEffect, useState } from 'react';
import ginux1 from "../assets/Ginux AI logo1 1.svg";
import ginux2 from "../assets/GinuxAi.svg";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import { FaXmark, FaBars } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  // set toggle Menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      }
      else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.addEventListener('scroll', handleScroll);
    }
  });

  //Navitem array
  const navItems = [
    { link: "Home", path: "home" },
    { link: "Feature", path: "feature" },
    // { link: "About", path: "About" },
    { link: "Product", path: "product" },
    { link: "Pricing", path: "price" },
    { link: "Testimonial", path: "Customer" },
  ];
  return (
    <header className="bg-transparent  top-0 left-0 right-0 ">
      <nav className={`py-4 lg:px-14 px-4  ${isSticky ? "sticky top-0 left-0 right-0 border-b duration-300" : ""}`}>
        <div className=" container mx-auto flex justify-between md:justify-center items-center text-base">
         
         <a href='#' className="flex gap-3 mr-8">
            <img className="block dark:hidden" src={ginux1} alt="logo" />
            <img src={ginux2} alt="logo" />
          </a>
         
         
          {/* nav Item for large device*/}
          <ul className="md:flex space-x-12 hidden gap-[30px]">
            {
              navItems.map(({ link, path }) => <ScrollLink to={path} spy={true} smooth={true} offset={-100}
                key={path}
                className="block text-base cursor-pointer text-white hover:text-brandPrimary first:font-medium">
                {link}</ScrollLink>)
            }
          </ul>

          {/* btn for for large devices */}
          <div className="space-x-10 hidden lg:flex items-center">
            <a
              href="https://app.ginuxai.com"
              className="hidden lg:flex items-center border ml-6 border-solid border-gray-600 px-7 py-2 hover:bg-green max-w-[200px] truncate"
              style={{
                display: 'flex',
                height: '47px',
                padding: '8px 24px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '40px',
                borderColor: 'var(--CTA, #4467FF)',
                boxShadow: '0px 0px 10px 0px #74F'
              }}
            >
              Sign in
            </a>

            <a
             href="https://app.ginuxai.com/auth/signup"
              className="flex items-center justify-center gap-7 w-120 h-47 px-7 py-2 ounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-purple-800 focus:ring-2 focus:ring-purple-500 focus:outline-none whitespace-nowrap"
              style={{
                borderRadius: '40px',
                background: 'var(--gradient, linear-gradient(90deg, #2B59FF 0%, #BB2BFF 100%))'
              }}
            >
              Sign Up
            </a>
          </div>

          {/* menu btn for only mobile device */}
          <div className="md:hidden ml-[50px]"> {/* This will display the element only on screens smaller than 920px (md breakpoint) */}
            {isMenuOpen ? (
              <button onClick={toggleMenu} className="text-white focus:outline-none focus:text-gray-500">
                <FaXmark className="h-6 w-6" />
              </button>
            ) : (
              <button onClick={toggleMenu} className="text-white focus:outline-none focus:text-gray-500">
                <FaBars className="h-6 w-6" />
              </button>
            )}
          </div>



        </div>
        {/* Nav item for mobile device*/}
        <div className={`space-y-4 px-4 mt-16 py-7 bg-gray-800 ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}>
          {navItems.map(({ link, path }) => (
            <ScrollLink
              to={path}
              spy={true}
              smooth={true}
              offset={-100}
              key={path}
              className="block text-white text-base  hover:text-red-100 first:font-medium mb-4"
              onClick={closeMenu}
            >
              {link}

            </ScrollLink>

          ))}
          <div className="flex flex-col items-center space-y-4">
          <a 
          href='https://app.ginuxai.com/auth/signup'
              className="flex items-center justify-center gap-7 w-120 h-47 px-10 py-3 ounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-purple-800 focus:ring-2 focus:ring-purple-500 focus:outline-none"
              style={{
                borderRadius: '40px',
                background: 'var(--gradient, linear-gradient(90deg, #2B59FF 0%, #BB2BFF 100%))'
              }}
            >
              Sign Up
            </a>
            
            <a
              href="https://app.ginuxai.com"
              className="hidden lg:flex items-center border  border-solid border-gray-600 px-7 py-2 hover:bg-green"
              style={{
                display: 'flex',
                height: '47px',
                padding: '8px 45px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '40px',
                borderColor: 'var(--CTA, #4467FF)',
                boxShadow: '0px 0px 10px 0px #74F'
              }}
            >
              Sign in
            </a>
          </div>
        </div>

      </nav>
    </header>

  )
}