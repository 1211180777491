import React,{useState} from 'react';
import playstoreImg from "../assets/playstore.svg";
import { Button, Modal } from 'flowbite-react';
const Home = () => {
    const [openModal, setOpenModal] = useState(false);
 
    return (
        <>
      

        
        <div  className="gradient-background  md:rounded-[50px] flex flex-col items-center justify-center p-4 md:p-8 lg:p-10 xl:p-16 md:min-h-full md:mx-24 mx-0" id="home">
        
      <Modal size="6xl"   position="center" dismissible show={openModal} onClick={()=>setOpenModal(false)}>
        
        <Modal.Body onClick={()=>setOpenModal(false)}>
          <div onClick={()=>setOpenModal(false)} className="space-y-6 h-[600px] lg:h-[500px] ">
          <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src={`https://www.youtube.com/embed/Ga96ukA84mg?rel=0`}
                  title="YouTube Video"
                  allowFullScreen
                />
          </div>
        </Modal.Body>
         
      </Modal>
      <div onClick={()=>setOpenModal(false)} className='w-full'></div>
      
            <div onClick={()=>setOpenModal(false)} className='flex  gap-2 items-center mb-5 pt-[30px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="27" viewBox="0 0 29 27" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.6579 0.870361C7.6579 0.870361 7.95991 3.68927 9.13629 4.86565C10.3127 6.04203 13.1316 6.34405 13.1316 6.34405C13.1316 6.34405 10.3127 6.64606 9.13629 7.82244C7.95991 8.99882 7.6579 11.8177 7.6579 11.8177C7.6579 11.8177 7.35589 8.99882 6.17951 7.82244C5.00313 6.64606 2.18422 6.34405 2.18422 6.34405C2.18422 6.34405 5.00313 6.04203 6.17951 4.86565C7.35589 3.68927 7.6579 0.870361 7.6579 0.870361ZM19.9737 4.97562C19.9737 4.97562 20.4267 9.20399 22.1913 10.9686C23.9558 12.7331 28.1842 13.1862 28.1842 13.1862C28.1842 13.1862 23.9558 13.6392 22.1913 15.4037C20.4267 17.1683 19.9737 21.3967 19.9737 21.3967C19.9737 21.3967 19.5207 17.1683 17.7561 15.4037C15.9915 13.6392 11.7632 13.1862 11.7632 13.1862C11.7632 13.1862 15.9915 12.7331 17.7561 10.9686C19.5207 9.20399 19.9737 4.97562 19.9737 4.97562ZM9.50589 18.1803C8.03542 16.7098 7.6579 13.1862 7.6579 13.1862C7.6579 13.1862 7.28039 16.7098 5.80991 18.1803C4.33944 19.6507 0.815796 20.0283 0.815796 20.0283C0.815796 20.0283 4.33944 20.4058 5.80991 21.8762C7.28039 23.3467 7.6579 26.8704 7.6579 26.8704C7.6579 26.8704 8.03542 23.3467 9.50589 21.8762C10.9764 20.4058 14.5 20.0283 14.5 20.0283C14.5 20.0283 10.9764 19.6507 9.50589 18.1803Z" fill="url(#paint0_linear_142_1686)" />
                    <defs>
                        <linearGradient id="paint0_linear_142_1686" x1="2.66719" y1="2.92299" x2="29.7027" y2="5.42135" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0695937" stop-color="#4467FF" />
                            <stop offset="0.816425" stop-color="#7241FF" />
                        </linearGradient>
                    </defs>
                </svg>
                <p className="font-avenir text-gray-300 text-xs md:text-base font-medium tracking-wider leading-6 md:leading-8 uppercase">
                    Perfect Solution For Research
                </p>

            </div>
            <h1 className="text-3xl md:text-5xl mb-[40px] text-center leading-snug">Experience the power of AI<br /><span className='pt-5'> in your academic journey</span></h1>
            <p className=' text-base text-slate-300 text-center mb-9 md:mb-7'>Enhance your research effortlessly using our AI-driven assistant<br /> designed specifically to suit your need</p>
            <div className=" flex flex-col  pb-[90px] md:flex-row space-y-4 md:space-y-0  md:space-x-4">
                 
                <button onClick={()=>window.location.assign("https://app.ginuxai.com/auth/signup")} className="w-full bg-black h-16 md:w-auto md:h-auto px-24 py-4 md:px-10 py-4 text-white font-bold rounded-full">
                    <span className='whitespace-nowrap'>Get Started For Free</span>
                </button>

                <button onClick={() => setOpenModal(true)} className="w-full bg-black h-16 md:w-auto md:h-auto px-24 py-4 md:px-10 py-4 text-white font-bold rounded-full flex items-center w-full md:w-auto mt-4 md:mt-0">
                    <img src={playstoreImg} className="w-6 md:w-8" alt="" />
                    <span className="ml-3 md:ml-5 mr-3 md:mr-5 whitespace-nowrap">Watch Demo</span>
                </button>
            </div>

        </div>
        </>
        
    );
}

export default Home;
