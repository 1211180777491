import React from "react";
import frameimg from "../assets/Frame (2) 1.png";

export default function User() {
    return (
        <div className="flex flex-col md:flex-row justify-center items-center pb-[75px] pt-[75px] gap-14 pt-10 md:pt-20 px-4 md:px-0" id="feature">
            <div className="bg-purple-700 rounded-lg p-4 w-full md:w-[550px] h-[400px] md:p-9 flex items-center justify-center">
                <img
                    src={frameimg}
                    alt="Description of the pic"
                    className="  w-full h-auto max-w-[412px] object-cover rounded-lg"
                />
            </div>
            <div>
                <h2 className="text-2xl font-bold mb-10">Seamless user-friendly interface</h2>
                <p className="text-gray-400 mb-10"> Our user-friendly dashboard is your academic command center.<br/> It provides a seamless and intuitive experience, allowing you to access all<br/> the features and tools in one place. From here, <br/>you can start new projects, <br/> track your progress, and manage your past assignments effortlessly..</p>
                <button onClick={()=>window.location.assign("https://app.ginuxai.com/auth/signup")} className="rounded-md border border-solid border-gray-600 bg-gradient-to-r from-blue-500 via-purple-500 to-purple-800 px-7 py-2 hover:bg-purple-700 hover:border-purple-900 hover:text-white">
                    Get Started Now
                </button>
            </div>
        </div>
    );
}
