import React from "react";
import ginux1 from "../assets/Ginux AI logo1 1.svg";
import ginux2 from "../assets/GinuxAi.svg";

function Footer() {
    return (
        <section className="pt-[84px] bg-black">
            <div className=" h-1/2 w-full flex md:flex-row flex-col justify-around items-start p-7 md:p-20">
                <div className=" p-2 md:p-5 ">
                    <div>
                        <div className="flex gap-3 mr-8">
                            <img className="block dark:hidden" src={ginux1} alt="logo" />
                            <img src={ginux2} alt="logo" />
                        </div>
                        <p className="pt-[20px]">Be the first to  receive all the recent updates, articles,<br /> and valuable materials.</p>

                        <div className="flex flex-col sm:flex-row gap-4 container mx-auto mt-4">
                            <input
                                type="text"
                                placeholder="Email Address"
                                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                            />

                            <button className="bg-purple-900  text-white rounded-lg px-4 py-2 w-full sm:w-auto">
                                <span>Subscribe</span>
                            </button>
                        </div>


                    </div>
                </div>
                <div className="pt-[50px] flex justify-center item-center align-center">
                    <ul>
                        <p className="text-white font-bold text-2xl pb-4">Quick Access</p>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            Accesibility
                        </li>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            Term & Condition
                        </li>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            Privacy & Policy
                        </li>

                    </ul>
                </div>
                <div className="pt-[50px]">
                    <ul>
                        <p className="text-white font-bold text-2xl pb-4">Resource</p>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            Help Center
                        </li>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            Legal Notice
                        </li>
                        <li className="text-white text-md pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            FAQ
                        </li>

                    </ul>
                </div>

            </div>

        </section>
    );
}

export default Footer;